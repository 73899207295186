<template>
  <div class="bg-gray-light">
    <container class="-mt-8 bg-transparent">
      <row class="flex-between">
        <column class="hidden md:flex md:w-7/12">
          <breadcrumbs
            :category-crumb="categoryCrumb"
            :custom-crumbs="customCrumbs"
            color="gray-black"
            :add-json-ld="true"
            :base-url-json-ld="baseUrlJsonLD"
          />
        </column>
        <column class="justify-center w-full md:w-5/12">
          <client-only>
            <locale-only :except="ArabicLocales">
              <vue-trustpilot
                :identifier="trustpilotIdentifier"
                class="p-2 pt-3 md:-me-20"
                :data-locale="trustpilotLocale"
              />
            </locale-only>
          </client-only>
        </column>
      </row>
    </container>
    <container class="bg-white sm:hidden">
      <row>
        <column class="w-full">
          <breadcrumbs :category-crumb="categoryCrumb" color="gray-black" />
        </column>
      </row>
    </container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import { Container, Row, Column } from '~/components/grid'
import { Breadcrumbs } from '~/components/molecules'
import LocaleOnly, {
  ArabicLocales,
} from '~/components/utils/locale-only/index.vue'
import { getUrlWithLocale } from '~/services/url'

export default {
  components: {
    Container,
    Row,
    Column,
    Breadcrumbs,
    LocaleOnly,
  },
  props: {
    color: VueTypes.string.def('white'),
    addJsonLd: VueTypes.bool.def(false),
    baseUrlJsonLd: VueTypes.string.def(''),
    categoryCrumb: VueTypes.shape({
      label: VueTypes.string.def(''),
      title: VueTypes.string.def(''),
      url: VueTypes.string.def('#'),
    }).def({}),
    customCrumbs: VueTypes.arrayOf(
      VueTypes.shape({
        label: VueTypes.string.def(''),
        title: VueTypes.string.def(''),
        url: VueTypes.string.def('#'),
        goBack: VueTypes.bool.def(false),
        id: VueTypes.string,
      }).loose
    ).def([]),
  },
  data() {
    return {
      ArabicLocales,
    }
  },
  computed: {
    ...mapGetters('context', [
      'trustpilotLocale',
      'locale',
      'direction',
      'isGlobalMarketplace',
      'marketplacePrefix',
    ]),
    trustpilotIdentifier() {
      return this.$mq === 'sm'
        ? `header-mobile-${this.direction}`
        : `header-desktop-${this.direction}`
    },
    baseUrlJsonLD() {
      const origin = process?.env?.DOMAIN || ''
      const currentLocale = this.isGlobalMarketplace
        ? this.marketplacePrefix
        : this.locale

      return `https://${getUrlWithLocale(origin, currentLocale)}`
    },
  },
}
</script>
